@use '@angular/material' as mat;
@use 'assets/github.scss';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

$md-blue: (
  50: #e1edfe,
  100: #b4d2fc,
  200: #82b4fa,
  300: #5095f7,
  400: #2b7ff6,
  500: #0568f4,
  600: #0460f3,
  700: #0455f1,
  800: #034bef,
  900: #013aec,
  A100: #ffffff,
  A200: #e0e5ff,
  A400: #adbbff,
  A700: #93a6ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@include mat.elevation-classes();
@include mat.app-background();

$app-primary: mat.m2-define-palette($md-blue);
$app-accent: mat.m2-define-palette(mat.$m2-pink-palette, 500, 900, A100);
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);

$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
    typography: mat.m2-define-typography-config(
        $font-family: 'Arial, sans-serif',
        $body-1: mat.m2-define-typography-level(
            $font-size: inherit,
            $letter-spacing: normal,
          ),
        $body-2: mat.m2-define-typography-level(
            $font-size: initial,
            $letter-spacing: normal,
          ),
        $button: mat.m2-define-typography-level(
            $font-size: initial,
            $letter-spacing: normal,
            $font-weight: 600,
          ),
      ),
    density: 0,
  )
);

@include mat.all-component-themes($app-theme);
