@use 'assets/github.scss';
@use 'ngx-diff/styles/default-theme';

$theme-primary: #3e236e;
$theme-secondary: #ffffff;

$default-font-stack: Cambria, 'Hoefler Text', Utopia, 'Liberation Serif',
  'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
$modern-font-stack: Constantia, 'Lucida Bright', Lucidabright, 'Lucida Serif',
  Lucida, 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', Georgia,
  serif;

html,
body {
  font-family: 'Arial', sans-serif;
  font-size: 16px;

  .content {
    padding: 0;
    margin: 0;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;

  code,
  pre {
    font-family: monospace;

    & * {
      font-family: inherit;
    }
  }
}

.table-data {
  width: 100%;
}

.table-header {
  margin: 10px;
}

.table-header-cell {
  padding: 10px;
}

.div-home {
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5em;
}

.state-approved {
  background-color: #43a047;
}

.state-rejected {
  background-color: orange;
}

.state-pending {
  background-color: yellow;
}

tr.detail-row {
  height: 0px;
}

tr.table-row:not(.expanded-table-row):hover {
  background: whitesmoke;
}

tr.table-row:not(.expanded-table-row):active {
  background: #efefef;
}

.table-row td {
  border-bottom-width: 0;
}

.table-row-detail {
  overflow: hidden;
  display: flex;
}

app-component {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}

.mat-mdc-drawer-container {
  flex: 1;
  height: 1000px !important;
}

nav > .mat-mdc-toolbar {
  background: $theme-secondary;
  color: #fff;

  .nav-logo {
    height: 28px;
  }

  .flex-spacer {
    flex: 1 1 auto;
  }

  a {
    text-decoration: none;
  }
}

.mobile-nav > a {
  flex: 1 1 auto;
}

.hide-small {
  @media screen and (max-width: 720px) {
    display: none !important;
  }
}

.show-small {
  @media screen and (min-width: 720px) {
    display: none !important;
  }
}

.mat-mdc-card {
  margin: 1em;
}

mat-drawer mat-nav-list .mat-mdc-icon {
  margin-right: 0.5em;
}

.text-center {
  text-align: center;
  justify-content: center;
}
